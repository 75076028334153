
  import {createControllersWithDescriptors, initAppForPageWrapper} from '@wix/yoshi-flow-editor/runtime/esm/viewerScript/wrapper.js';
  
  
            
import wrapController0 from '@wix/yoshi-flow-editor-runtime/internal/viewerScript/ooi';

            import controller0 from '/home/builduser/work/3399d9d8df658024/spotify-ooi/src/components/Spotify/controller.ts';
            import * as _controllerExport0 from '/home/builduser/work/3399d9d8df658024/spotify-ooi/src/components/Spotify/controller.ts';
            var controllerExport0 = _controllerExport0;
            

            
import wrapController1 from '@wix/yoshi-flow-editor-runtime/internal/viewerScript/ooi';

            import controller1 from '/home/builduser/work/3399d9d8df658024/spotify-ooi/src/components/SpotifySearch/controller.ts';
            import * as _controllerExport1 from '/home/builduser/work/3399d9d8df658024/spotify-ooi/src/components/SpotifySearch/controller.ts';
            var controllerExport1 = _controllerExport1;
            

  
  import * as viewerApp from '/home/builduser/work/3399d9d8df658024/spotify-ooi/src/viewer.app.ts';
    var importedApp = viewerApp;


  

    import { initState, initBind } from '@wix/yoshi-flow-editor/runtime/esm/velocycleMobx';
    import { observable } from 'mobx';
    var velocycleMobx = { initState, initBind, observable };
    


  

    var blocksControllerService = null;
    

  
var createHttpClient = null;

  

  import { initI18nWithoutICU as initI18n } from '@wix/yoshi-flow-editor/runtime/esm/i18next/init';


  
const multilingualDisabled = false;

  

    var createExperiments = null;
    var createWidgetExperiments = null;
    


  var sentryConfig = {
      DSN: 'https://01fb0c955b864231aa35321b27af56a8@sentry.wixpress.com/440',
      id: '01fb0c955b864231aa35321b27af56a8',
      projectName: 'spotify',
      teamName: 'music',
      errorMonitor: true,
    };

  var experimentsConfig = null;

  var translationsConfig = {"icuEnabled":false,"defaultTranslationsPath":"/home/builduser/work/3399d9d8df658024/spotify-ooi/src/assets/locales/messages_en.json","availableLanguages":["ar","bg","ca","cs","da","de","el","en","es","fi","fr","he","hi","hu","id","it","ja","ko","lt","ms","nl","no","pl","pt","ro","ru","sk","sl","sv","th","tl","tr","uk","vi","zh"]};

  var defaultTranslations = {"searcher.album":"Albums","searcher.song":"Tracks","searcher.playlist":"Playlists","searcher.artist":"Artists","searcher.select":"Select","searcher.cancel":"Cancel","search.start.searchHeader":"Search for albums, tracks, playlists or artists","settings.tabs.main":"Main","settings.tabs.settings":"Settings","settings.main.loginInfo":"Spotify log-in is required","settings.main.loginButton":"Log-in","settings.main.searchSpotifyButton":"Search Spotify","settings.main.intro":"Stream your favorite music from Spotify directly on your website. Search albums, tracks, playlists or artists.","settings.settings.spotifyUriTitle":"Spotify URI","settings.settings.spotifyUriPlaceholder":"Paste it here","settings.settings.loginButton":"Log-in","settings.settings.updateButton":"Update","settings.settings.popupInfo":"To find a Spotify URI: enter the Spotify desktop app > click the 3 dots on your track, album or playlist > click Share > and select Copy Spotify URI.","settings.settings.popupError":"Check the Spotify URI and try again","settings.settings.search":"Search Spotify","settings.settings.changeMusic":"Change Music","settings.settings.spotifyTitle":"Search Spotify","search.error.title":"Something went wrong","search.error.description":"Please try again later.","search.noResults.track":"No results found in Tracks","search.noResults.album":"No results found in Albums","search.noResults.artist":"No results found in Artists","search.noResults.playlist":"No results found in Playlists","search.noResults.tryDifferent":"Try searching again using a different spelling or keyword.","search.tableView.number":"NO.","search.tableView.track":"TRACK NAME","search.tableView.artist":"ARTIST NAME","search.tableView.album":"ALBUM","search.textInput.placeholder":"Search","search.textInput.xTitle":"Search","search.textInput.inputTitle":"Type what you want to find then press Enter"};

  var fedopsConfig = null;

  import { createVisitorBILogger as biLogger } from '/home/builduser/work/3399d9d8df658024/spotify-ooi/target/generated/bi/createBILogger.ts';

  export const exports = importedApp.exports;

  export const initAppForPage = initAppForPageWrapper({
    initAppForPage: importedApp.initAppForPage,
    sentryConfig: sentryConfig,
    experimentsConfig: experimentsConfig,
    inEditor: false,
    biLogger: biLogger,
    multilingualDisabled,
    projectName: "spotify-ooi",
    biConfig: null,
    appName: "Spotify",
    appDefinitionId: "099ac204-be88-4ed1-89af-cf14358c83f1",
    fedopsConfig: fedopsConfig,
    translationsConfig: translationsConfig,
    defaultTranslations: defaultTranslations,
    shouldUseEssentials: true,
    optionalDeps: {
      initI18n,
      createHttpClient,
      createExperiments,
    },
    localeDistPath: "assets/locales",
  });

  const _createControllers = createControllersWithDescriptors({
    initI18n,
    blocksControllerService,
    createHttpClient,
    createExperiments,
    velocycleMobx,
  }, [{ method: controller0,
          wrap: wrapController0,
          exports: controllerExport0,
          widgetType: "WIDGET_OUT_OF_IFRAME",
          translationsConfig,
          multilingualDisabled,
          experimentsConfig: null,
          fedopsConfig: fedopsConfig,
          sentryConfig: sentryConfig,
          persistentAcrossPages: false,
          biLogger: biLogger,
          shouldUseEssentials: true,
          withErrorBoundary: false,
          biConfig: null,
          controllerFileName: "/home/builduser/work/3399d9d8df658024/spotify-ooi/src/components/Spotify/controller.ts",
          appName: "Spotify",
          appDefinitionId: "099ac204-be88-4ed1-89af-cf14358c83f1",
          projectName: "spotify-ooi",
          componentName: "Spotify",
          localeDistPath: "assets/locales",
          defaultTranslations: defaultTranslations,
          id: "53d0a5fa-3da5-418b-84d6-e58bd55fecad" }, { method: controller1,
          wrap: wrapController1,
          exports: controllerExport1,
          widgetType: "WIDGET_OUT_OF_IFRAME",
          translationsConfig,
          multilingualDisabled,
          experimentsConfig: null,
          fedopsConfig: fedopsConfig,
          sentryConfig: sentryConfig,
          persistentAcrossPages: false,
          biLogger: biLogger,
          shouldUseEssentials: true,
          withErrorBoundary: false,
          biConfig: null,
          controllerFileName: "/home/builduser/work/3399d9d8df658024/spotify-ooi/src/components/SpotifySearch/controller.ts",
          appName: "Spotify",
          appDefinitionId: "099ac204-be88-4ed1-89af-cf14358c83f1",
          projectName: "spotify-ooi",
          componentName: "SpotifySearch",
          localeDistPath: "assets/locales",
          defaultTranslations: defaultTranslations,
          id: "spotifySearch" }],
    true);

    export const createControllers = _createControllers
